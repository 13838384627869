// AddressAutocomplete.js
import React, { useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import TextField from './text-field';
import { useEffect } from 'react';

export default function AddressAutocomplete({ value, onChange, disabled, required }) {
    const [autoFilledAddress, setAutoFilledAddress] = useState(value);
    const [addressSelected, setAddressSelected] = useState(Boolean(value));

    useEffect(() => {
        if (value) {
            setAutoFilledAddress(value);
            setAddressSelected(true);
        }
    }, [value]);

    return !addressSelected ? (
        <div className="flex flex-col">
            <label className="text-sm font-medium mb-4 bg-none">Company Address <span className='text-rose-600'>*</span></label>
            <GooglePlacesAutocomplete
                required={required}
                selectProps={{
                    value: autoFilledAddress
                        ? { label: autoFilledAddress, value: autoFilledAddress }
                        : null,
                    onChange: (val) => {
                        if (val && val.label) {
                            setAutoFilledAddress(val.label);
                            onChange(val.label); // Pass the selected address up
                            setAddressSelected(true);
                        }
                    },
                    placeholder: 'Start typing your address...',
                    isDisabled: disabled,
                    styles: {
                        input: (provided) => ({
                            ...provided,
                            color: 'var(--color-gray-700)',
                        }),
                        option: (provided) => ({
                            ...provided,
                            color: 'var(--color-gray-700)',
                            backgroundColor: 'var(--color-primary)',
                        }),
                        singleValue: (provided) => ({
                            ...provided,
                            color: 'var(--color-gray-700)',
                            backgroundColor: 'var(--color-primary)',
                        }),
                    }
                }}
                apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
            />
        </div>
    ) : (
        <div className="flex flex-col">
            <label className="text-sm font-medium mb-1 bg-none">Company Address <span className='text-rose-600'>*</span></label>
            <TextField
                type="text"
                className="bg-primary"
                inputClass={'text-xs'}
                value={autoFilledAddress}
                onChange={(e) => {
                    console.log(e);
                    setAutoFilledAddress(e);
                    onChange(e);
                }}
                disabled={disabled}
                required={required}
                showAsterisk={false}
            />
        </div>
    );
}
