import { useState } from 'react';
import { api } from '../../api.service';
import { toast, ToastContainer } from 'react-toastify';
import { uploadFile } from '../../service/files';

export default function FileField({
  label,
  value,
  onChange,
  className,
  labelClass,
  source,
  hideNote = false,
  disabled = false,
  required = false,
  returnFileObject = false, // New optional prop
}) {
  const [documentFile, setDocumentFile] = useState(value ?? {});
  const [loading, setIsLoading] = useState(null);

  const startLoading = () => {
    setIsLoading(0);
    const interval = setInterval(() => {
      setIsLoading((prev) => {
        if (prev === 97) {
          clearInterval(interval);
          return 97;
        }
        if (prev === 100) {
          clearInterval(interval);
          return 100;
        }
        return prev + 1;
      });
    }, 100);
  };

  const finishLoading = () => {
    setIsLoading(100);
    setTimeout(() => {
      setIsLoading(null);
    }, 2000);
  };

  const handleUploadFile = async (file) => {
    if (!file) return;
    const formData = new FormData();
    formData.append('file', file);
    const sourceData = { source };
    formData.append('sourceData', JSON.stringify(sourceData));
    startLoading();
    try {
      const response = await uploadFile(formData);
      finishLoading();
      setDocumentFile(response);

      // If returnFileObject is true, return { id, file }, otherwise just the docId
      if (typeof onChange === 'function') {
        if (returnFileObject) {
          onChange({ id: response._id, file: file });
        } else {
          onChange(response._id);
        }
      }
      toast.success('Document uploaded successfully');
    } catch (error) {
      finishLoading();
      toast.error('Document upload failed');
    }
  };

  const handleDownloadDocument = async () => {
    if (!documentFile?.key) return;
    const response = await api(
      `${process.env.REACT_APP_API_URL}/document/download-url?key=${documentFile.key}`
    );
    const link = document.createElement('a');
    link.href = response.url;
    link.setAttribute('target', '_blank');
    link.setAttribute('download', '');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  return (
    <div className={className}>
      <ToastContainer position="top-center" autoClose={2000} />
      {label && (
        <div
          className={`text-sm font-medium leading-6 text-gray-800 ${labelClass}`}
        >
          {label} {required && <span className="text-red-500">*</span>}
        </div>
      )}
      {required && !label && (
        <p className='font-medium text-sm'>
          File Upload <span className="text-red-500">*</span>
        </p>
      )}
      <div className="flex flex-col">
        <div
          onClick={handleDownloadDocument}
          className="flex-1 block text-xs font-medium text-left underline cursor-pointer text-gray-700 animate-fade"
        >
          {documentFile && (
            <p className='text-sm truncate overflow-hidden w-full'>{documentFile.key}</p>
          )}
        </div>
      </div>
      {loading !== null && (
        <div className="relative w-full h-2 mt-2 bg-neutral-200 rounded animate-fade">
          <div
            className="absolute top-0 left-0 h-2 bg-yellow-500 rounded"
            style={{ width: `${loading}%` }}
          ></div>
        </div>
      )}
      {!disabled && (
        <label className="text-sm font-medium text-center text-gray-800">
          <input
            onChange={(e) => handleUploadFile(e.target.files[0])}
            type="file"
            name="file"
            className="opacity-0"
            required={required && !documentFile.key}
          />
          <div
            type='button'
            className="w-full p-2 text-sm font-medium text-center bg-gray-200 rounded shadow cursor-pointer text-gray-700 hover:scale-105 hover:bg-gray-300 border hover:text-gray-900 transition-all"
          >
            <span>{documentFile.key ? 'Replace' : 'Upload'} File</span>
          </div>
        </label>
      )}
      {documentFile.key && hideNote === false && (
        <div>
          <p className="text-xs opacity-50 mt-1">
            Note - please submit the form to save the file.
          </p>
        </div>
      )}
    </div>
  );
}
