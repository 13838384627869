import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { api, api_delete, api_update } from '../api.service';
import { BillsTable } from '../bills';
import Confirm from '../components/confirm';
import { InvoicesTable } from '../invoices';
import {
  CurrencyField,
  DateField,
  FileField,
  SelectField,
  TextAreaField,
  TextField,
} from './fields/';
import FormWrapper from './form-wrapper';
import { toast } from 'react-toastify';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { LlmFileExtractor } from '../components/llm-file-extractor';

export default function ProjectForm(props) {
  const params = useParams();
  const navigate = useNavigate();
  let id = params.id ?? props.id;
  let [searchParams] = useSearchParams();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [bills, setBills] = useState([]);
  const [userData, setUserData] = useState();
  const buildrfiFinancingEnabled = useFeatureFlagEnabled('buildrfi_financing');

  // New feature flag for LLM extraction
  const llmExtractionEnabled = useFeatureFlagEnabled('bill_llm_extraction');

  const [initialised, setInitialised] = useState(false);
  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/app/bill`).then((x) => {
      setBills(x.filter((b) => b.project === id));
    });
    api(`${process.env.REACT_APP_API_URL}/app/invoice`).then((x) => {
      setInvoices(x.filter((inv) => inv.project === id));
    });
  }, [id]);

  function validation(values) {
    if (validCompletionDate(values.startDate, values.completedDate) === false) {
      return false;
    }
    return true;
  }

  function validCompletionDate(start, completed) {
    if (start && completed) {
      return completed.valueOf() > start.valueOf();
    }
    return true;
  }

  function deleteProject() {
    api_delete(`${process.env.REACT_APP_API_URL}/app/project/${id}`).then(() =>
      navigate(-1),
    );
  }

  useEffect(() => {
    if (window.lendica && userData && !initialised && !buildrfiFinancingEnabled) {
      setInitialised(true);
      const credentials = {
        partner_name: process.env.REACT_APP_LENDICA_PARTNER_NAME,
        partner_company_uuid: userData.companyData.id,
        company_name: userData.companyData.companyName,
      };
      window.lendica.init(credentials).then(() => {
        window.lendica.ibranch.render();
      });
    }
  }, [window.lendica, userData]);

  const openFundNow = (id, total) => {
    window.lendica.ibranch.openFundNow(id, total);
  };
  const openPayLater = (id, total) => {
    window.lendica.ibranch.openPayLater(id, total);
  };

  async function handleFormSubmit(values) {
    console.log(values?.document);
    if (values?.document) {
      await api_update(
        `${process.env.REACT_APP_API_URL}/document/${values?.document}`,
        { source: 'project', sourceId: values._id },
      );
    }

    if (searchParams.get('rd')) {
      navigate(searchParams.get('rd'));
    } else {
      const path = '/project/' + values.data._id;
      navigate(path);
      toast.success('Project created successfully');
    }
  }

  // Example config for LlmFileExtractor:
  const llmConfig = {
    name: "the project name mentioned in the contract",
    location: "the location of the project from the contract",
    description: "a brief description of the project from the contract",
    projectOwner: "the name of the project owner from the contract",
    mainContact: "the main contact person for the project as listed in the contract",
    mainContactEmail: "the email of the main contact person from the contract",
    startDate: "the project start date mentioned in the contract (in a recognizable date format)",
    completedDate: "the target or expected completion date of the project from the contract (in a recognizable date format)",
    status: "the current project status (ONLY THESE STATUSES: In Review, Active, or Complete). Use the start date to determine if the project is active or completed.",
    totalPrice: "the total contract value or total price of the project mentioned in the contract",
    deposit: "the deposit amount paid upfront as listed in the contract",
    totalEstimatedCosts: "the total estimated costs for completing the project from the contract",
  };

  return (
    <div className="sm:px-4">
      <Confirm
        open={confirmDelete}
        confirm={() => deleteProject()}
        cancel={() => setConfirmDelete(false)}
      />
      <FormWrapper
        url="app/project"
        id={id}
        {...props}
        callback={props.callback || (async (values) => handleFormSubmit(values))}
        validate={validation}
        noStyle
      >
        {(values, setValues) => (
          <>
            <div className="text-3xl font-bold tracking-tighter text-gray-800">
              Project Details
            </div>

            <div className="grid grid-cols-1 gap-x-4 sm:grid-cols-2 gap-y-6">
              <TextField
                label="Name"
                value={values.name}
                onChange={(val) => setValues({ ...values, name: val })}
                required
              />
              <TextField
                label="Location"
                value={values.location}
                onChange={(val) => setValues({ ...values, location: val })}
              />

              {/* Conditionally render File Field or LLMFileExtractor */}
              {llmExtractionEnabled ? (
                <LlmFileExtractor
                  label="Contract"
                  value={values.document}
                  config={llmConfig}
                  source="project"
                  required
                  onExtracted={(data) => {
                    const formatDate = (date) => {
                      if (!date || !Date.parse(date)) {
                        return null;
                      }
                      return new Date(date).toISOString().split("T")[0];
                    };
                    const tryParseNumber = (str, fallback) => {
                      const num = parseFloat((str || "").replace(/[^0-9.]/g, ''));
                      return isNaN(num) ? fallback : num;
                    };
                    setValues((prevValues) => ({
                      ...prevValues,
                      name: data.name ?? values.name,
                      location: data.location ?? values.location,
                      description: data.description ?? values.description,
                      projectOwner: data.projectOwner ?? values.projectOwner,
                      mainContact: data.mainContact ?? values.mainContact,
                      mainContactEmail: data.mainContactEmail ?? values.mainContactEmail,
                      startDate: formatDate(data.startDate) ?? formatDate(values.startDate),
                      completedDate: formatDate(data.completedDate) ?? formatDate(values.completedDate),
                      status: data.status ?? values.status,
                      totalPrice: data.totalPrice
                        ? tryParseNumber(data.totalPrice, values.totalPrice)
                        : values.totalPrice,
                      deposit: data.deposit
                        ? tryParseNumber(data.deposit, values.deposit)
                        : values.deposit,
                      totalEstimatedCosts: data.totalEstimatedCosts
                        ? tryParseNumber(data.totalEstimatedCosts, values.totalEstimatedCosts)
                        : values.totalEstimatedCosts,
                    }));
                  }}
                  onChange={(val) => {
                    setValues({ ...values, document: val });
                  }}
                />
              ) : (
                <FileField
                  label="Contract"
                  value={values.document}
                  onChange={(val) => setValues({ ...values, document: val })}
                  source="project"
                  required
                />
              )}

              <TextAreaField
                label="Description"
                value={values.description}
                onChange={(val) => setValues({ ...values, description: val })}
                inputClass="h-12 mt-1"
              />
            </div>

            <div className="grid grid-cols-1 gap-6 sm:grid-cols-3 my-6">
              <TextField
                label="Project Owner"
                value={values.projectOwner}
                onChange={(val) => setValues({ ...values, projectOwner: val })}
                required
              />
              <TextField
                label="Main Contact"
                value={values.mainContact}
                onChange={(val) => setValues({ ...values, mainContact: val })}
                required
              />
              <TextField
                label="Main Contact Email"
                value={values.mainContactEmail}
                onChange={(val) => setValues({ ...values, mainContactEmail: val })}
                required
              />
              <DateField
                label="Project Start Date"
                value={values.startDate}
                onChange={(val) => setValues({ ...values, startDate: val })}
                required
              />
              <DateField
                label="Target Completion Date"
                value={values.completedDate}
                onChange={(val) => setValues({ ...values, completedDate: val })}
                isValid={validCompletionDate(values.startDate, values.completedDate)}
                required
                invalidText="Must be after Start Date"
              />
              <SelectField
                options={[
                  { text: 'In Review', value: 'In Review' },
                  { text: 'Active', value: 'Active' },
                  { text: 'Complete', value: 'Complete' },
                ]}
                label="Project Status"
                value={values.status}
                onChange={(val) => setValues({ ...values, status: val })}
              />
              <CurrencyField
                label="Project Contract Value"
                value={values.totalPrice}
                onChange={(val) => setValues({ ...values, totalPrice: val })}
                min={0}
              />
              <CurrencyField
                label="Deposit Paid"
                value={values.deposit}
                onChange={(val) => setValues({ ...values, deposit: val })}
                min={0}
              />
              <CurrencyField
                label="Total Estimated Costs"
                value={values.totalEstimatedCosts}
                onChange={(val) => setValues({ ...values, totalEstimatedCosts: val })}
                min={0}
              />
            </div>

            {id !== 'new' && (
              <div className="relative">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setConfirmDelete(true);
                  }}
                  className="absolute top-0 left-0 inline-flex items-center justify-center px-4 py-2 mt-5 text-sm font-medium text-white border border-transparent rounded shadow-sm cursor-pointer hover-lift bg-rose-500 hover:bg-rose-600 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2"
                >
                  Delete
                </button>
              </div>
            )}
          </>
        )}
      </FormWrapper>

      {!props.simple && (
        <>
          <div className="my-5 text-2xl font-semibold text-gray-800">Bills</div>
          <BillsTable openBill={openPayLater} data={bills} />

          <div className="my-5 text-2xl font-semibold text-gray-800">Invoices</div>
          <InvoicesTable open={openFundNow} data={invoices} />
        </>
      )}
    </div>
  );
}
