import React from 'react';
import Modal from '../components/modal';
import BillForm from './bill-form';

export default function EmbeddedBillCreationForm({ isOpen, onClose, onBillCreated }) {
    if (!isOpen) return null;

    async function handleBillCreated(values) {
        if (onBillCreated) {
            onBillCreated(values);
        }
        onClose();
    }

    return (
        <Modal onClose={onClose} darken={true} containerClassName="w-full h-full max-h-[75vh] max-w-[75vw] mx-auto p-6 flex flex-col overflow-scroll">
            <BillForm
                id="new"
                noStyle={true}
                callback={handleBillCreated}
            />
        </Modal>
    );
}
