import { useState, useEffect } from 'react';
import FileField from '../forms/fields/file-field';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import Modal from '../components/modal';
import { api_post } from '../api.service';
import { SparklesIcon } from '@heroicons/react/24/outline';
import Loading from './loading';
import { toast } from 'react-toastify'; // NEW IMPORT

export const LlmFileExtractor = ({
    config,
    extraDocumentData,        // NEW PROP
    onExtracted,
    onChange,
    hideTitle = false,
    label,
    value,
    className,
    labelClass,
    source,
    disabled = false,
    required = false
}) => {
    const [processing, setProcessing] = useState(false);
    const [extractedData, setExtractedData] = useState(null);
    const [showSuccessBanner, setShowSuccessBanner] = useState(false);

    const editVariablesEnabled = useFeatureFlagEnabled('internal_extraction_tools');
    const [showModal, setShowModal] = useState(false);

    // Local copies of config and extraDocumentData so we can edit them
    const [editableConfig, setEditableConfig] = useState({});
    const [editableExtraDocumentData, setEditableExtraDocumentData] = useState({}); // NEW STATE

    useEffect(() => {
        setEditableConfig({ ...config });
    }, [config]);

    useEffect(() => {
        setEditableExtraDocumentData({ ...extraDocumentData });
    }, [extraDocumentData]);

    const handleFileChange = async (fileInfo) => {
        if (!fileInfo || !fileInfo.file) return;

        // Call onChange with only the doc ID, not the entire object
        if (typeof onChange === 'function') {
            onChange(fileInfo.id);
        }

        setProcessing(true);
        try {
            const pdfBase64 = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const dataUrl = e.target.result;
                    const base64 = dataUrl.split(',')[1];
                    resolve(base64);
                };
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(fileInfo.file);
            });

            // Pass both config and extraDocumentData to the extraction API
            const data = await api_post(`${process.env.REACT_APP_API_URL}/app/extract-variables`, {
                pdfBase64,
                config: editableConfig,
                extraDocumentData: editableExtraDocumentData, // NEW
            });

            // If the backend response indicates an error, handle it
            if (data?.error) {
                throw new Error(data.error);
            }

            setExtractedData(data);

            // Fire onExtracted callback (if any)
            if (typeof onExtracted === 'function') {
                await onExtracted(data);
            }

            // Show success banner only if everything succeeded
            setShowSuccessBanner(true);
            setTimeout(() => {
                setShowSuccessBanner(false);
            }, 3000);
        } catch (error) {
            console.error('Extraction failed:', error);
            toast.error(`Could not extract data, please enter fields manually`);
        } finally {
            setProcessing(false);
        }
    };

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        document.body.classList.remove('overflow-hidden');
    };

    // Handlers for editing the config instructions
    const handleEditConfigChange = (key, val) => {
        setEditableConfig({
            ...editableConfig,
            [key]: val
        });
    };

    // Handlers for editing the extraDocumentData instructions
    const handleEditExtraDocChange = (key, val) => {
        setEditableExtraDocumentData({
            ...editableExtraDocumentData,
            [key]: val
        });
    };

    const handleSaveConfig = () => {
        // This is where you'd persist changes if needed.
        setShowModal(false);
    };

    return (
        <div className={className} style={{ position: 'relative', height: '160px' }}>
            {processing && (
                <div className="absolute inset-0 backdrop-blur-[3px] flex flex-col items-center justify-center z-50 rounded-lg overflow-hidden">
                    <div className="absolute inset-0 bg-gray-500 opacity-70"></div>
                    <div className="flex flex-row items-center justify-center relative z-10">
                        <SparklesIcon className="w-12 h-12 text-white animate-pulse mb-4 mx-2" />
                        <div className="text-white text-lg font-semibold mb-4 ml-2">
                            Uploading file and extracting data with AI...
                        </div>
                        <div className="w-32 h-32 ml-[2vw] mr-4">
                            <Loading />
                        </div>
                    </div>
                </div>
            )}

            {/* Success Banner */}
            {showSuccessBanner && (
                <div className="absolute inset-0 backdrop-blur-[3px] flex flex-col items-center justify-center z-50 rounded-lg overflow-hidden">
                    <div className="absolute inset-0 bg-gray-500 opacity-70"></div>
                    <div className="flex flex-row items-center justify-center relative z-10">
                        <SparklesIcon className="w-12 h-12 text-white animate-pulse mb-4" />
                        <div className="text-white text-lg font-semibold mb-4 ml-2 flex">
                            <p className='animate-tada mr-2'>Success!</p> Data extracted from file.
                        </div>
                    </div>
                </div>
            )}

            <FileField
                label={label}
                value={value}
                onChange={handleFileChange}
                className={className}
                labelClass={labelClass}
                source={source}
                hideNote={hideTitle}
                disabled={disabled || processing}
                required={required}
                returnFileObject={true}
            />

            {editVariablesEnabled && (
                <div className="mt-2">
                    <button
                        onClick={handleOpenModal}
                        className="inline-flex items-center px-2 py-1 text-sm font-medium text-gray-700 bg-gray-200 rounded hover:bg-gray-300 transition-colors"
                        type='button'
                    >
                        <span className="mr-1">✨</span> Manage Config
                    </button>
                </div>
            )}

            {showModal && (
                <Modal onClose={handleCloseModal} darken={true} containerClassName="max-w-md w-full">
                    <h2 className="text-xl font-semibold mb-4">Edit Extraction Instructions</h2>
                    <p className="text-sm text-gray-700 mb-2">
                        Adjust the instructions for each variable. These instructions guide the LLM on what to extract.
                    </p>

                    {/* Config Editing */}
                    <h3 className="text-md font-semibold mb-2">Config Instructions</h3>
                    <div className="flex flex-col gap-2 max-h-36 overflow-auto mb-4">
                        {Object.keys(editableConfig).map((key) => (
                            <div key={key} className="flex flex-col">
                                <label className="text-sm font-medium text-gray-700">
                                    {key}
                                </label>
                                <input
                                    type="text"
                                    className="border rounded p-1"
                                    value={editableConfig[key] ?? ''}
                                    onChange={(e) => handleEditConfigChange(key, e.target.value)}
                                />
                            </div>
                        ))}
                    </div>

                    {/* Extra Document Data Editing */}
                    {Object.keys(editableExtraDocumentData).length > 0 && (
                        <>
                            <h3 className="text-md font-semibold mb-2">Extra Document Data</h3>
                            <div className="flex flex-col gap-2 max-h-36 overflow-auto mb-4">
                                {Object.keys(editableExtraDocumentData).map((key) => (
                                    <div key={key} className="flex flex-col">
                                        <label className="text-sm font-medium text-gray-700">
                                            {key}
                                        </label>
                                        <input
                                            type="text"
                                            className="border rounded p-1"
                                            value={editableExtraDocumentData[key] ?? ''}
                                            onChange={(e) => handleEditExtraDocChange(key, e.target.value)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </>
                    )}

                    <div className="flex justify-end mt-4 gap-2">
                        <button
                            onClick={handleSaveConfig}
                            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded hover:bg-blue-700"
                        >
                            Save
                        </button>
                    </div>
                </Modal>
            )}
        </div>
    );
};
