import ReactInputMask from 'react-input-mask';

export default function EINField({
    label,
    value,
    onChange,
    className,
    inputClass,
    labelClass,
    placeholder,
    onBlur,
    type,
    required,
    onValid,
    disabled = false,
}) {
    const handleInputChange = (e) => {
        const newValue = e.target.value;
        const isValid = /^\d{2}-\d{7}$/.test(newValue);
        onChange(newValue);
        if (onValid) {
            onValid(isValid);
        }
    };

    return (
        <div className={className + ' mt-2'}>
            <label
                className={`block text-sm font-medium leading-6 text-gray-800 ${labelClass}`}
            >
                {label} {required && <span className="text-rose-600">*</span>}
            </label>
            <div className="mt-1">
                <ReactInputMask
                    mask="99-9999999" // Mask for EIN format
                    value={value}
                    onChange={handleInputChange}
                    disabled={disabled}
                >
                    {(inputProps) => (
                        <input
                            {...inputProps}
                            required={required}
                            type={type ? type : 'text'}
                            onBlur={onBlur}
                            placeholder={placeholder || 'XX-XXXXXXX'}
                            className={`block w-full border px-3 p-2 bg-primary shadow placeholder:text-gray-600 outline-none text-sm leading-6 ${inputClass} ${disabled ? 'bg-gray-100 cursor-not-allowed' : ''}`}
                        />
                    )}
                </ReactInputMask>
            </div>
        </div>
    );
}
