import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InlineWidget } from "react-calendly";
import {
    BanknotesIcon,
    BuildingLibraryIcon,
    WrenchScrewdriverIcon,
} from '@heroicons/react/20/solid';
import { api, api_post } from '../api.service';
import { toast, ToastContainer } from 'react-toastify';
import {
    PhoneField,
    EINField,
    FileField,
    TextField,
    SelectField,
} from './fields';
import { getUserAsCustomer } from '../utils';
import QuickbooksButton from '../components/quickbooks-button';
import PlaidButton from '../components/plaid-button';
import EmbeddedProjectCreationForm from './embedded-project-creation-form';
import EmbeddedBillCreationForm from './embedded-bill-creation-form';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useSearchParams } from 'react-router-dom';
import { getBusinessAsCustomer } from '../service/business';
import AddressAutocomplete from './fields/address-autocomplete';


export default function CreditApplicationForm() {

    const navigate = useNavigate();
    const [disabled, setDisabled] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [calculatedQuoteAmount, setCalculatedQuoteAmount] = useState(null);
    const [isEinValid, setIsEinValid] = useState(false);
    const [isFormDisabled, setIsFormDisabled] = useState(false);
    const [user, setUser] = useState(getUserAsCustomer());
    const quickbooksEnabled = useFeatureFlagEnabled('quickbooks_button');
    const plaidEnabled = useFeatureFlagEnabled('plaid_button');
    const [plaidConnectionStatus, setPlaidConnectionStatus] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const initialStep = parseInt(searchParams.get('step')) || 1;
    const [currentStep, setCurrentStep] = useState(initialStep);
    const [completedSteps, setCompletedSteps] = useState(
        initialStep > 1 ? Array.from({ length: initialStep }, (_, i) => i + 1) : [1]
    );

    const [isProjectFormOpen, setIsProjectFormOpen] = useState(false);
    const [projectCreated, setProjectCreated] = useState(false);
    const [createdProjectData, setCreatedProjectData] = useState(null);

    const [isBillFormOpen, setIsBillFormOpen] = useState(false);
    const [billCreated, setBillCreated] = useState(false);
    const [createdBillData, setCreatedBillData] = useState(null);

    const [autoFilledAddress, setAutoFilledAddress] = useState(null);
    const [addressSelected, setAddressSelected] = useState(false);

    function handleBillCreated(values) {
        setBillCreated(true);
        setCreatedBillData(values);
        toast.success('Bill created successfully');
        formData['status'] = 'pending';
        saveApplicationData();
    }

    function handleProjectCreated(values) {
        setProjectCreated(true);
        setCreatedProjectData(values);
        toast.success('Project created successfully');
    }

    async function handleNewApplication() {
        const user = await getUserAsCustomer();
        const business = await getBusinessAsCustomer();
        const businessName = business?.name || user.businessName || '';
        setFormData({
            applicationData: {
                companyLegalName: businessName,
            },
            status: 'Incomplete',
        });
        setUser(user);
    }

    useEffect(() => {
        setSearchParams({ step: currentStep });
    }, [currentStep, setSearchParams]);

    useEffect(() => {
        if (!localStorage.getItem('settingsOnboarding')) {
            toast.success(
                'Welcome to BuildrFi! Fill in your business information to get started.'
            );
            localStorage.setItem('settingsOnboarding', 't');
        }
    }, []);

    const [formData, setFormData] = useState({
        applicationData: {
            // Page 1 fields
            companyLegalName: '',
            einNumber: '',
            dateOfIncorporation: '',
            companyAddress: '',
            phoneNumber: '',
            website: '',
            monthlyRevenue: '',
            monthlyDebtPayment: '',
            averageProjectValuePerMonth: '',
            typesOfProjectsYouWorkOn: '',
            // Page 2 fields
            bankAccountFile: null,
            quickbooksFile: null,
            projectManagementFile: null,
        },
        status: 'Incomplete',
    });

    const steps = [
        { step: 1, label: 'Tell us More About Your Business' },
        { step: 2, label: 'Upload Documents' },
        { step: 3, label: 'Create Project' },
        { step: 4, label: 'Add Your First Bill' },
    ];

    useEffect(() => {
        const fetchCreditApplication = async () => {
            try {
                const response = await api(
                    `${process.env.REACT_APP_API_URL}/credit-application/application`
                );
                if (response?.applicationData) {
                    setFormData(response);
                    if (
                        ['pending', 'rejected', 'verified'].includes(
                            response?.status?.toLowerCase()
                        )
                    ) {
                        setIsFormDisabled(true);
                    }
                } else {
                    // No application found
                    await handleNewApplication();
                }
            } catch (error) {
                console.error('Error fetching credit application:', error);
            }
        };

        fetchCreditApplication();
    }, []);

    const handleInputChange = (key, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            applicationData: {
                ...prevFormData.applicationData,
                [key]: value,
            },
        }));
    };

    const calculateQuoteAmount = () => {
        const monthlyRevenue =
            parseFloat(formData.applicationData.monthlyRevenue) || 0;
        const monthlyDebtPayment =
            parseFloat(formData.applicationData.monthlyDebtPayment) || 0;
        const averageProjectValuePerMonth =
            parseFloat(formData.applicationData.averageProjectValuePerMonth) || 0;

        const loanPercentage = 0.2; // 20%
        const depositPercentage = 0.25; // 25%

        const adjustedLiabilities =
            monthlyDebtPayment * 3 - averageProjectValuePerMonth * 3 * depositPercentage;
        const preApproval = monthlyRevenue * 3 * loanPercentage - adjustedLiabilities;

        let finalAmount = preApproval > 0 ? preApproval : 1000;
        finalAmount = Math.min(finalAmount, 200000);
        return finalAmount;
    };

    const getQuoteAmount = () => {
        if (currentStep === 1) {
            return '$XX,XXX';
        } else {
            return calculateQuoteAmount().toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            });
        }
    };

    const saveApplicationData = () => {
        let data = { ...formData };
        api_post(`${process.env.REACT_APP_API_URL}/credit-application`, data).then(
            (response) => {
                setFormData(response);
            }
        );
    };

    const replayQuoteAnimation = () => {
        const quoteElement = document.getElementById('header-quote');
        quoteElement.classList.remove('animate-tada');
        void quoteElement.offsetWidth;
        quoteElement.classList.add('animate-tada');
    };

    const handleNext = (e) => {
        e.preventDefault();
        setDisabled(true);

        if (currentStep === 1) {
            const {
                companyLegalName,
                dateOfIncorporation,
                companyAddress,
                phoneNumber,
                monthlyRevenue,
                monthlyDebtPayment,
                averageProjectValuePerMonth,
                typesOfProjectsYouWorkOn,
                einNumber,
            } = formData.applicationData;

            if (
                !companyLegalName ||
                !einNumber ||
                !dateOfIncorporation ||
                !companyAddress ||
                !phoneNumber ||
                !monthlyRevenue ||
                !monthlyDebtPayment ||
                !averageProjectValuePerMonth ||
                !typesOfProjectsYouWorkOn
            ) {
                alert('Please fill in all mandatory fields.');
                setDisabled(false);
                return;
            }
        }

        const nextStep = currentStep + 1;
        setCurrentStep(nextStep);
        setCompletedSteps((prevSteps) => [...prevSteps, nextStep]);
        saveApplicationData();
        setDisabled(false);
        replayQuoteAnimation();
    };

    const handleNextStep2 = () => {
        const { bankAccountFile, quickbooksFile, projectManagementFile } =
            formData.applicationData;
        const quickbooksConnected = completedIntegrations.quickbooks === true && quickbooksEnabled;
        const plaidConnected = completedIntegrations.plaid === true && plaidEnabled;

        if (
            !(bankAccountFile || plaidConnected) ||
            !(quickbooksFile || quickbooksConnected) ||
            !projectManagementFile
        ) {
            alert('Please upload files or complete integrations for all sections.');
            return;
        }

        saveApplicationData();
        setCurrentStep(currentStep + 1);
    };

    const handleBack = () => {
        const prevStep = currentStep - 1;
        if (prevStep >= 1) {
            setCurrentStep(prevStep);
        }
    };

    const closeModal = () => {
        setShowModal(false);
        const nextStep = currentStep + 1;
        setCurrentStep(nextStep);
        setCompletedSteps((prevSteps) => [...prevSteps, nextStep]);
        const amount = calculateQuoteAmount();
        setCalculatedQuoteAmount(amount);
    };

    const [completedIntegrations, setCompletedIntegrations] = useState({});

    const handleQuickbooksConnectionStatus = (status) => {
        if (status === 'connected') {
            setCompletedIntegrations((prev) => ({ ...prev, quickbooks: true }));
        }
    };

    const renderIntegrationSection = ({
        key,
        serviceName,
        fileLabel,
        uploadLabel,
        file,
        setFile,
        comingSoonImage,
        integrationName,
        renderIcon,
    }) => {
        let isCompleted = !!file || completedIntegrations[key] === true;

        if (plaidEnabled && plaidConnectionStatus === 'connected' && key === 'plaid') {
            isCompleted = true;
        }

        const borderClass = isCompleted ? 'border-green-400' : 'border-gray-300';

        return (
            <div
                key={key}
                className={`flex items-center justify-between border-4 relative ${borderClass} rounded-md px-2 py-6 mb-4 bg-primary min-h-48 transition-all ${isCompleted ? 'bg-primary' : 'bg-opacity-80'}`}
            >
                {renderIcon}
                <div className="w-1/2 overflow-hidden flex flex-col items-center">
                    <FileField
                        label={fileLabel}
                        value={file}
                        onChange={(val) => {
                            setFile(val);
                            setCompletedIntegrations((prev) => ({ ...prev, [key]: true }));
                        }}
                        required
                        fileName={file?.name}
                        source="creditApplication"
                        className={'w-3/4'}
                        labelClass={'text-xs'}
                        hideNote={true}
                        disabled={isFormDisabled}
                    />
                </div>

                <div className="text-gray-500">Or</div>
                {key === 'quickbooks' && quickbooksEnabled ? (
                    <div className="text-sm w-1/2 flex flex-col items-center">
                        <QuickbooksButton onStatusChange={handleQuickbooksConnectionStatus} />
                    </div>
                ) : key === 'plaid' && plaidEnabled ? (
                    <div className="text-sm w-1/2 flex flex-col items-center">
                        <PlaidButton
                            text="Bank Account"
                            connectionStatus={plaidConnectionStatus}
                            setConnectionStatus={(status) => {
                                setPlaidConnectionStatus(status);
                                if (status === 'connected') {
                                    setCompletedIntegrations((prev) => ({ ...prev, plaid: true }));
                                }
                            }}
                            simple={true}
                            user={user}
                        />
                    </div>
                ) : (
                    <div className="text-sm w-1/2 flex flex-col items-center">
                        <button
                            className="px-6 py-3 rounded-md bg-gray-300 flex items-center justify-start cursor-not-allowed text-sm w-3/4 text-center font-semibold transition-all text-opacity-30"
                            disabled
                        >
                            <img
                                src={comingSoonImage}
                                alt={integrationName}
                                className="w-6 h-6 mr-2 bg-primary rounded-full p-0.5"
                            />
                            {integrationName}{' '}
                            <span className="text-gray-500 text-xs ml-1 font-medium">
                                (Coming Soon)
                            </span>
                        </button>
                    </div>
                )}
            </div>
        );
    };

    const renderStepContent = (step) => {
        switch (step) {
            case 1:
                return (
                    <form onSubmit={handleNext}>
                        <div className="grid grid-cols-1 gap-y-5 gap-x-16 sm:grid-cols-2">
                            <TextField
                                label="Company Legal Name"
                                value={formData.applicationData.companyLegalName}
                                required
                                inputClass={'text-xs'}
                                disabled
                            />
                            <EINField
                                label="EIN Number"
                                value={formData.applicationData.einNumber}
                                onChange={(val) => handleInputChange('einNumber', val)}
                                onValid={(isValid) => setIsEinValid(isValid)}
                                required
                                inputClass={'text-xs'}
                                disabled={isFormDisabled}
                            />
                            <TextField
                                label="Date of Incorporation"
                                type="date"
                                value={formData.applicationData.dateOfIncorporation}
                                onChange={(val) => handleInputChange('dateOfIncorporation', val)}
                                required
                                inputClass="border-gray-200 mt-1 text-xs"
                                disabled={isFormDisabled}
                            />
                            <AddressAutocomplete
                                value={formData.applicationData.companyAddress}
                                onChange={(val) => handleInputChange('companyAddress', val)}
                                disabled={isFormDisabled}
                                required={true}
                            />
                            <PhoneField
                                label="Phone Number"
                                value={formData.applicationData.phoneNumber}
                                onChange={(val) => handleInputChange('phoneNumber', val)}
                                required
                                inputClass={'text-xs'}
                                disabled={isFormDisabled}
                            />
                            <TextField
                                label="Website"
                                value={formData.applicationData.website}
                                onChange={(val) => handleInputChange('website', val)}
                                inputClass={'text-xs'}
                                disabled={isFormDisabled}
                            />

                            {/* Monthly Revenue */}
                            <SelectField
                                label="Monthly Revenue"
                                value={formData.applicationData.monthlyRevenue}
                                onChange={(val) => handleInputChange('monthlyRevenue', val)}
                                options={[
                                    { text: '$0 - $50,000', value: '0.00' },
                                    { text: '$50,000 - $100,000', value: '50000.00' },
                                    { text: '$100,000 - $250,000', value: '100000.00' },
                                    { text: '$250,000 - $500,000', value: '250000.00' },
                                    { text: '$500,000+', value: '500000.00' },
                                ]}
                                required
                                inputClass={'text-xs'}
                                disabled={isFormDisabled}
                                placeholder="Select Range"
                            />

                            {/* Monthly Debt Payment */}
                            <SelectField
                                label="Monthly Debt Payment"
                                value={formData.applicationData.monthlyDebtPayment}
                                onChange={(val) => handleInputChange('monthlyDebtPayment', val)}
                                options={[
                                    { text: '$0 - $10,000', value: '0.00' },
                                    { text: '$10,000 - $25,000', value: '10000.00' },
                                    { text: '$25,000 - $50,000', value: '25000.00' },
                                    { text: '$50,000+', value: '50000.00' },
                                ]}
                                required
                                inputClass={'text-xs'}
                                disabled={isFormDisabled}
                                placeholder="Select Range"
                            />

                            {/* Average Project Value Per Month */}
                            <SelectField
                                label="Average Project Value Per Month"
                                value={formData.applicationData.averageProjectValuePerMonth}
                                onChange={(val) => handleInputChange('averageProjectValuePerMonth', val)}
                                options={[
                                    { text: '$0 - $100,000', value: '0.00' },
                                    { text: '$100,000 - $250,000', value: '100000.00' },
                                    { text: '$250,000 - $500,000', value: '250000.00' },
                                    { text: '$500,000+', value: '500000.00' },
                                ]}
                                required
                                inputClass={'text-xs'}
                                disabled={isFormDisabled}
                                placeholder="Select Range"
                            />

                            <SelectField
                                label="Types of Projects You Work On"
                                value={formData.applicationData.typesOfProjectsYouWorkOn}
                                onChange={(val) => handleInputChange('typesOfProjectsYouWorkOn', val)}
                                options={[
                                    { value: '', text: 'Select an option' },
                                    { value: 'Residential', text: 'Residential' },
                                    { value: 'Commercial', text: 'Commercial' },
                                    { value: 'Both', text: 'Both' },
                                ]}
                                required
                                disabled={isFormDisabled}
                            />
                        </div>
                        <div className="mt-6 flex space-x-4">
                            {!isFormDisabled && <button
                                type="submit"
                                className={`px-4 py-2 bg-yellow-500 rounded-md hover:bg-yellow-600 text-black font-semibold ${disabled ? 'opacity-50' : ''
                                    }`}
                                disabled={disabled}
                            >
                                Save and Continue
                            </button>}
                        </div>
                    </form>
                );
            case 2:
                return (
                    <div className="max-w-1/2">
                        {renderIntegrationSection({
                            key: 'quickbooks',
                            serviceName: 'Financial Statements',
                            fileLabel: 'Upload Financial Statements (Last Year)',
                            uploadLabel: 'Upload',
                            file: formData.applicationData.quickbooksFile,
                            setFile: (file) => handleInputChange('quickbooksFile', file),
                            comingSoonImage: 'quickbooks-coming-soon.png',
                            integrationName: 'Quickbooks',
                            renderIcon: (
                                <BanknotesIcon className="w-6 h-6 mr-2 text-gray-500 absolute top-2 right-2" />
                            ),
                        })}
                        {renderIntegrationSection({
                            key: 'plaid',
                            serviceName: 'Bank Statements',
                            fileLabel: 'Upload Bank Statements (Last 6 months)',
                            uploadLabel: 'Upload',
                            file: formData.applicationData.bankAccountFile,
                            setFile: (file) => handleInputChange('bankAccountFile', file),
                            comingSoonImage: 'bank-coming-soon.png',
                            renderIcon: (
                                <BuildingLibraryIcon className="w-6 h-6 mr-2 text-gray-500 absolute top-2 right-2" />
                            ),
                            integrationName: 'Bank Account',
                        })}
                        {renderIntegrationSection({
                            key: 'projectManagement',
                            serviceName: 'Work In Progress',
                            fileLabel: 'Upload WIP Report (Most Recent)',
                            uploadLabel: 'Upload',
                            file: formData.applicationData.projectManagementFile,
                            setFile: (file) => handleInputChange('projectManagementFile', file),
                            comingSoonImage: 'project-management-coming-soon.png',
                            integrationName: 'Projects',
                            renderIcon: (
                                <WrenchScrewdriverIcon className="w-6 h-6 mr-2 text-gray-500 absolute top-2 right-2" />
                            ),
                        })}
                        {!isFormDisabled && <div className="mt-6 flex space-x-4">
                            <button
                                type="button"
                                onClick={handleBack}
                                className="px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400 font-semibold"
                            >
                                Back
                            </button>
                            <button
                                type="button"
                                onClick={handleNextStep2}
                                className={`px-4 py-2 bg-yellow-500 rounded-md hover:bg-yellow-600 text-black font-semibold ${disabled ? 'opacity-50' : ''
                                    }`}
                                disabled={disabled}
                            >
                                Submit
                            </button>
                        </div>}
                        {showModal && (
                            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                                <div className="bg-primary rounded-lg text-center overflow-hidden max-w-[640px] pb-10">
                                    <img
                                        src="mail.jpg"
                                        alt="Header"
                                        className=" w-[640px] mb-10"
                                        onClick={() => closeModal()}
                                    />
                                    <h2 className="text-3xl font-bold mb-4">
                                        You are Pre-Approved for{' '}
                                        <span className="inline-block animate-tada">
                                            {calculateQuoteAmount().toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                            })}
                                            !
                                        </span>
                                    </h2>
                                    <p className="my-8 px-4">
                                        Get ready to build with{' '}
                                        {calculateQuoteAmount().toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        })}{' '}
                                        in pre-approved financing! We will reach out to you within{' '}
                                        <strong>24 hours</strong> regarding your acceptance to the BuildrFi financing
                                        program.{' '}
                                    </p>
                                    <button
                                        className="px-4 py-2 bg-yellow-500 rounded-md hover:bg-yellow-600 font-semibold text-black"
                                        onClick={() => {
                                            closeModal();
                                        }}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                );
            case 3:
                return (
                    <div className="text-center h-full flex flex-col items-center justify-center pb-48 my-auto">
                        {
                            isFormDisabled ?
                                (<div className="flex flex-col items-center justify-center">
                                    <h2 className="text-2xl font-semibold mb-4 mt-12">Thanks for submitting your first project!</h2>
                                </div>)
                                : !projectCreated ?
                                    (<>
                                        <h3 className="text-3xl font-semibold mb-4">
                                            You're pre-approved for up to: {getQuoteAmount()}
                                        </h3>
                                        <p className="mb-6 text-xl">Create your first project and bill to get started.</p>
                                        <button
                                            className="px-12 py-3 text-lg bg-yellow-500 rounded-md hover:bg-yellow-600 font-semibold mt-8 hover:scale-105 transition-all text-black"
                                            onClick={() => {
                                                setIsProjectFormOpen(true);
                                            }}
                                            disabled={isFormDisabled}
                                        >
                                            Create Project
                                        </button>
                                    </>)
                                    :
                                    (<div className="flex flex-col items-center justify-center">
                                        <h2 className="text-2xl font-semibold mb-4 mt-12">Thanks for submitting your first project!</h2>
                                        {/* Next step and add your first bill */}
                                        <button
                                            className="px-12 py-3 text-lg bg-yellow-500 rounded-md hover:bg-yellow-600 font-semibold mt-8 hover:scale-105 transition-all text-black"
                                            onClick={() => {
                                                setCurrentStep(4);
                                            }}
                                        >
                                            Next Step: Add Your First Bill
                                        </button>
                                    </div>)
                        }

                        <EmbeddedProjectCreationForm
                            isOpen={isProjectFormOpen}
                            onClose={() => {
                                setIsProjectFormOpen(false)
                                saveApplicationData();
                                document.body.classList.remove('overflow-hidden');
                                setIsBillFormOpen(true);
                            }}
                            onProjectCreated={async () => handleProjectCreated()}
                        />
                    </div>
                );
            case 4:
                return (
                    <div className="text-center h-full flex flex-col items-center justify-center pb-48 my-auto">
                        {
                            isFormDisabled ?
                                (<div className="flex flex-col items-center justify-center">
                                    <h2 className="text-2xl font-semibold mb-4 mt-12">Thanks for submitting your first bill!</h2>
                                </div>)
                                :
                                !billCreated ? (
                                    <>
                                        <h3 className="text-3xl font-semibold mb-4">
                                            Great! Now add your first bill
                                        </h3>
                                        <p className="mb-6 text-xl">Create your first bill to continue.</p>
                                        <button
                                            className="px-12 py-3 text-lg bg-yellow-500 rounded-md hover:bg-yellow-600 font-semibold mt-8 hover:scale-105 transition-all text-black"
                                            onClick={() => {
                                                setIsBillFormOpen(true);
                                            }}
                                            disabled={isFormDisabled}
                                        >
                                            Create Bill
                                        </button>
                                    </>
                                ) : (
                                    <div className="flex flex-col items-center justify-center">
                                        <h2 className="text-2xl font-semibold mb-4 mt-12">Thanks for adding your first bill!</h2>
                                        {/* Next steps if any */}
                                        {/* Perhaps a button to finish onboarding */}
                                        <button
                                            className="px-12 py-3 text-lg bg-yellow-500 rounded-md hover:bg-yellow-600 font-semibold mt-8 hover:scale-105 transition-all text-black"
                                            onClick={() => {
                                                navigate(`/bill/${createdBillData._id}`);
                                            }}
                                        >
                                            Take me to the bill
                                        </button>
                                        <button
                                            className="px-12 py-3 text-lg bg-yellow-500 rounded-md hover:bg-yellow-600 font-semibold mt-8 hover:scale-105 transition-all text-black"
                                            onClick={() => {
                                                navigate('/');
                                            }}
                                        >
                                            Dashboard
                                        </button>
                                    </div>
                                )}

                        <EmbeddedBillCreationForm
                            isOpen={isBillFormOpen}
                            onClose={() => { setIsBillFormOpen(false); document.body.classList.remove('overflow-hidden'); }}
                            onBillCreated={async (values) => handleBillCreated(values)}
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="lg:max-w-6xl mx-auto p-6 bg-primary rounded-lg border">
            <h2 className="text-2xl font-bold mb-4 flex items-center">
                Unlock up to
                <span id="header-quote" className="text-4xl ml-2 animate-tada inline-block">
                    {getQuoteAmount() &&
                        `${getQuoteAmount().toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`}
                </span>
                <div className="ml-auto bg-secondary-200 p-2 rounded-md text-base">
                    Application Status: <span className='capitalize' >{formData?.status}</span>
                </div>
            </h2>
            <ToastContainer position="top-center" autoClose={2000} />
            <div className="flex bg-secondary rounded-md p-6 border">
                <div className="w-2/3 max-w-[2/3]">
                    <div className="flex space-x-4 mb-6">
                        {steps.map((item) => (
                            <button
                                key={item.step}
                                className={`py-2 px-4 rounded-md box-border transition-all hover:scale-105 hover:shadow-md
                                    ${currentStep === item.step
                                        ? 'bg-yellow-500 text-black font-semibold'
                                        : 'bg-primary border'
                                    } ${completedSteps.includes(item.step) || isFormDisabled
                                        ? ''
                                        : 'cursor-not-allowed opacity-50'
                                    }`}
                                onClick={() => {
                                    if (isFormDisabled || completedSteps.includes(item.step)) {
                                        setCurrentStep(item.step);
                                    }
                                }}
                                disabled={!completedSteps.includes(item.step) && !isFormDisabled}
                            >
                                {item.label}
                            </button>
                        ))}
                    </div>
                    <div className="h-full">{renderStepContent(currentStep)}</div>
                </div>
                <div className="w-1/3 max-w-[1/3] pl-4">
                    <div className="bg-primary p-4 rounded shadow">
                        <div className="flex">
                            <img
                                src="steph.png"
                                alt="Stephanie"
                                className="rounded-full w-20 h-20 mr-3"
                            />
                            <h3 className="text-base lg:text-xl font-bold mb-4 mt-3">
                                Have questions? Let's Talk:
                            </h3>
                        </div>
                        <div className="w-full flex flex-col items-center justify-center">
                            <p className="text-sm flex items-center mt-3 text-gray-700">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-5 h-5 mr-1"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                                    />
                                </svg>
                                stephanie@buildrfi.com
                            </p>
                        </div>

                        <InlineWidget
                            styles={{ height: '500px' }}
                            pageSettings={{
                                backgroundColor: 'ffffff',
                                hideEventTypeDetails: true,
                                hideLandingPageDetails: true,
                            }}
                            url="https://calendly.com/stephanie-buildrfi/let-s-chat?primary_color=f6cc45"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
